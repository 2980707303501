import React from 'react';
import { Card, Row, Col, Button, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components'
import truncate from 'lodash/truncate';

const { Text } = Typography

const StyledCard = styled(Card)`
    width: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.10);
    border: none;

    & .ant-card-body {
        height: 100%;
        display: grid;
    }
`;

const Title = styled.h2`
    color: #7bad7e;
`

const StyledButtonGroup = styled.div`
    width: 100%;
    margin-top: 16px;
    display: flex;
    gap: 8px;
    justify-self: flex-end;
    justify-content: center;

    & button {
        width: 100px;
    }
`

const StyledCol = styled(Col)`
    & > div {
        display:  flex;
        flex-direction:  column;

        strong {
            color: #424647;
            font-weight: 400;
        }
      
        span {
            color: #171c2f;
            font-weight: 700;
            font-size: .8rem;
        }
    }
`
const DeleteButton = styled(Button)`
    background-color: #ff4d4f;
    color: #fff;

    &:hover {
        backgroundColor: #fff;
        border-color: #ff4d4f;
        color: #ff4d4f;
    }
`
const EditButton = styled(Button)`
    background-color: #7BAD7E;
    color: #fff;

    &:hover {
        backgroundColor: #fff;
        border-color: #7BAD7E;
        color: #7BAD7E;
    }
`

const StyledTag = styled.span`
    display: inline-block;
    background-color: #7BAD7E;
    border-radius: 15px;
    padding: 2px 10px;
    color: #fff;
    margin: 3px;
`

const truncateString = str => truncate(str, { length: 35 })

const TemplateAttribute = ({ name, value, width }) => {
    return (
        <StyledCol span={width}>
            <div><strong>{name}</strong> <span>{value}</span></div>
        </StyledCol>
    )
}

const TelemedicineCategoryCollection = ({ telemedicineCategories }) => {
    const categoryNames = telemedicineCategories.map(category => category.name)
    return (
        <StyledCol span={24} style={{ maxHeight: '200px', overflowY: 'scroll'}}>
            { categoryNames.map(name => (
                <StyledTag>{name}</StyledTag>
            ))}
        </StyledCol>
    )
}

export const Template = ({ template, selectTemplate, handleDeleteTemplate }) => {
    const {
        _id,
        medication,
        pharmacyNotes,
        directions,
        title,
        createdAt,
        updatedAt,
        teleMedicineCategoryIds,
        DAW
    } = template

    const {
        drugName,
        strength,
    } = medication

    return (
        <StyledCard>
            <Title>{ title }</Title>
            <Row gutter={[16, 16]}>
                <TemplateAttribute name="Medication" value={drugName} width={24} />
                <TemplateAttribute name="Strength" value={strength} width={12} />
                <TemplateAttribute name="Pharmacy Notes" value={!!pharmacyNotes?.length ? truncateString(pharmacyNotes) : 'none'} width={24} />
                <TemplateAttribute name="Patient Directions" value={truncateString(directions)} width={24} />
                <TelemedicineCategoryCollection telemedicineCategories={teleMedicineCategoryIds}/>
            { DAW && <TemplateAttribute name="" value={"*Dispense As Written"} width={24} /> }
            </Row>
            <Row><Text style={{ fontSize: '.75rem'}}>Created: { new Date(createdAt).toLocaleDateString() }</Text></Row>
            <Row><Text style={{ fontSize: '.75rem'}}>Last updated: { new Date(updatedAt).toLocaleDateString() }</Text></Row>
            <StyledButtonGroup>
                <DeleteButton icon={<DeleteOutlined/>} onClick={() => handleDeleteTemplate(_id)}>Delete</DeleteButton>
                <EditButton icon={<EditOutlined />} onClick={() => selectTemplate(template)}>Edit</EditButton>
            </StyledButtonGroup>
        </StyledCard>
    );
};

