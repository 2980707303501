import { useState, useCallback, useRef, useMemo } from 'react';
import { debounce } from 'lodash'
import { message } from 'antd'

import { searchMedications } from '../../../../../api/PrescriptionsApi'

export const useSearchMedications = ({ 
    form,
 }) => {
    const [medicationSearchMatches, setMedicationSearchMatches] = useState([])
    const [medicationSearchLoading, setMedicationSearchLoading] = useState(false)
    const [availableMedicationStrengths, setAvailableMedicationStrengths] = useState([]);

    const abortController = useRef(null)

    const fetchMedications = useCallback(async (searchStr) => {
        setMedicationSearchMatches([])

        if (searchStr?.length <= 2) {
            setMedicationSearchLoading(false);
            return;
        }

        try {
            abortController.current?.abort()
            abortController.current = new AbortController()

            setMedicationSearchLoading(true)
            const { data: { medications } } = await searchMedications(searchStr, abortController.current?.signal)

            if (abortController.current?.signal.aborted || !medications?.length) {
                return
            }

            setMedicationSearchMatches(medications); 
        } catch (e) {
            console.error(e);
            message.error('Error fetching medication matches from prescription service');
        } finally {
            setMedicationSearchLoading(false);
        }
    }, [])

    const handleSelectMedication = medicationName => {
        form.resetFields([
            'ndc',
            'strength',
            'dispensableDrugId',
            'ingredients',
            'dispenseUnitId',
            'schedule'
        ])

        setAvailableMedicationStrengths([])

        const medicationMatch = medicationSearchMatches.find(medication => medication.name === medicationName);
        if (!medicationMatch) return;

        const isCompound = medicationMatch.ingredients?.length

        if (isCompound) {            
            form.setFieldsValue({ 
                strength: 'compound', 
                ingredients: medicationMatch.ingredients,
                dispenseUnitId: medicationMatch.dispenseUnitId 
            })

            return setAvailableMedicationStrengths([{ strength: 'compound' }]);
        } 

        setAvailableMedicationStrengths(medicationMatch.strengths);
    };

    const handleSelectStrength = strength => {
        const medication = availableMedicationStrengths.find(medStrength => medStrength.strength === strength)

        if (!medication) return;

        form.setFieldsValue({ 
            ndc: medication.ndc,
            dispenseUnitId: medication.dispenseUnitId,
            dispensableDrugId: medication.dispensableDrugId,
            schedule: medication.schedule
        })
    }

    const debounceFetchMedications = useMemo(() => debounce(fetchMedications, 1000), []);

    return { 
        fetchMedications: debounceFetchMedications, 
        medicationSearchMatches, 
        medicationSearchLoading,
        handleSelectMedication,
        handleSelectStrength,
        setMedicationSearchMatches,
        setMedicationSearchLoading,
        availableMedicationStrengths,
    }
}