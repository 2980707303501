import React, { useEffect } from "react"

import { 
  Form, 
  Input, 
  Row, 
  Col, 
  Select, 
  Button, 
  AutoComplete, 
  Spin,
  Typography,
  Checkbox,
} from "antd"

import { PlusCircleOutlined } from '@ant-design/icons';


import styled from 'styled-components'

import { useSearchMedications } from "../hooks/useSearchMedications";
import { useTelemedicineCategoryContext } from "../../../../../context/TelemedicineCategoryContext";

const { TextArea } = Input

const StyledFormItem = styled(Col)`
  & label {
    text-transform: capitalize;
  }
`

const StyledButtonGroup = styled(Row)`
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    & button {
        width: 100px;
        background: #fff;
        color: #171c2f;
    }
`

const FormItem = ({ span, children, ...props }) => (
  <StyledFormItem span={span || 12}>
    <Form.Item {...props} label={props.label || props.name}>
      {children}
    </Form.Item>
  </StyledFormItem>
);

export const PrescriptionTemplateForm = ({
  submit,
  selectedTemplate,
  handleFinish
}) => {

  const {
    telemedicineCategories,
    getTelemedicineCategoryIdByName,
  } = useTelemedicineCategoryContext()
 
  const [form] = Form.useForm();

  const {
    fetchMedications,
    medicationSearchMatches,
    availableMedicationStrengths,
    medicationSearchLoading,
    handleSelectMedication,
    handleSelectStrength,
    setMedicationSearchLoading,
    setMedicationSearchMatches
  } = useSearchMedications({
    form,
    selectedTemplate
  })

  const telemedicineCategoryOptions = telemedicineCategories.map(category => ({
    label: category.name,
    value: category.name
  }))

  const handleSubmit = () => {
    const values = form.getFieldsValue()
    const teleMedicineCategoryIds = values.teleMedicineCategories.map(getTelemedicineCategoryIdByName)
    
    submit({ 
      ...values, 
      teleMedicineCategoryIds,
      setLoading: setMedicationSearchLoading
    })

    setMedicationSearchMatches([])
    form.resetFields()
  }

  const _handleFinish = () => {
    form.resetFields()
    handleFinish()
  }

  useEffect(() => {
    if (selectedTemplate) {
      const { medication, teleMedicineCategoryIds, ...rest } = selectedTemplate
      const telemedicineCategoryNames = teleMedicineCategoryIds.map(category => category.name)
      form.setFieldsValue({ ...medication, ...rest, teleMedicineCategories: telemedicineCategoryNames })
    }
    return () => form.resetFields()
  }, [selectedTemplate])

  return (
    <Spin spinning={medicationSearchLoading}>
      <Form
        form={form}
        layout="vertical"
        style={{ textTransform: 'capitalize' }}
        validateMessages={{ required: 'required' }}
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <FormItem required span={24} name="title" label="Template Name">
            <Input placeholder="Template Name" />
          </FormItem>
          <FormItem required span={24} name="drugName" label="Medication">
            <AutoComplete
              onSelect={handleSelectMedication}
              onSearch={fetchMedications}
              placeholder="Search medication name"
            >
              {medicationSearchMatches?.map(medication => <Select.Option key={medication.name} value={medication.name}>{medication.name}</Select.Option>)}
            </AutoComplete>
          </FormItem>
          <FormItem
            span={24}
            label="Pseudonyms"
            name="pseudonyms"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Alternative names for requested medication"
            />
          </FormItem>
          <FormItem
            span={24}
            label="Telemedicine Categories"
            name="teleMedicineCategories"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={ !!selectedTemplate ? "None selected" : "Select Telemedicine Categories" }
              options={telemedicineCategoryOptions}
            />
          </FormItem>
          <FormItem
            span={12}
            label="Strength"
            name="strength"
            rules={[{ required: true }]}
          >
            <Select 
              placeholder="Select strength" 
              onSelect={handleSelectStrength}
              options={availableMedicationStrengths?.map(({ strength }) => ({ label: strength, value: strength })) || []} 
            />
          </FormItem>
          <FormItem
            required
            rules={[{ required: true }]}
            span={24}
            label="Patient Directions"
            name="directions"

          >
            <TextArea placeholder="Enter Patient Directions" />
          </FormItem>
          <FormItem span={24} label="Pharmacy Notes" name="pharmacyNotes">
            <TextArea placeholder="Enter Pharmacy Notes" />
          </FormItem>
          <Form.Item 
            span={24} 
            name="DAW" 
            valuePropName="checked"
            style={{ marginLeft: '7px'}}
          >
              <Checkbox>
                <Typography.Text>Dispense as Written</Typography.Text>
              </Checkbox>
          </Form.Item>
          <StyledButtonGroup>
            <Button onClick={_handleFinish}>Cancel</Button>
            <Button
              style={{ background: '#7BAD7E', color: '#fff' }}
              icon={!selectedTemplate && <PlusCircleOutlined />}
              htmlType="submit"
            >
              {selectedTemplate ? 'Save' : 'Create'}
            </Button>
          </StyledButtonGroup>
        </Row>
        <Form.Item hidden name="ndc" />
        <Form.Item hidden name="dispensableDrugId" />
        <Form.Item hidden name="ingredients" />
        <Form.Item hidden name="dispenseUnitId" />
        <Form.Item hidden name="schedule" />
      </Form>
    </Spin>
  );
};
